.smallViewer {
    background-color: #2d2d33;
    height:13em !important;
    width:100% !important;
}

.bigViewer {
    background-color: #2d2d33;
    position:fixed !important;
    width: 50vw !important;
    height: 75% !important;
    /* top:50% !important; */
    bottom: 0 !important;
    left:50% !important;
    transform: translate(-50%, 0%) !important;
    z-index: 999 !important;
    /* margin-bottom: 2em !important; */
}

.largeControls {
    position:fixed !important;
    width: 50vw !important;
    /* top: calc(75% + 3.55em) !important; */
    bottom: 0 !important;
    left:50% !important;
    transform: translate(-50%, 0%) !important;
    z-index: 999!important;
    /* margin-bottom: 2em !important; */
    /* padding-bottom: 2em !important; */
}
.smallControls {
    position: absolute !important;
    left:50% !important;
    transform: translate(-50%,-50%) !important;
    width: calc(100% - (2.5rem + 0.36rem + 16px));
}

#cover {
    position:fixed;
      top:0;
      left:0;
      background:rgba(0,0,0,0.6);
      z-index:990 !important;
      width:100%;
      height:100%;
}

.btn {
    border: none!important;
}

.flexy {
    height: 3em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flexy2 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}


.floatR {
    float: right !important;
}

.smallPosition {
    position: absolute;
    top: calc(2rem + 14em + 0.5rem);
    background-color: rgba(0, 0, 0, 0.6);
    padding: 0.5em;
    display: inline-block;
    z-index: 9999;
    padding-top: 0;
    padding-bottom: 0;
}

.largePosition {
    background-color: rgba(0, 0, 0, 0.6);
    padding: 0.5em;
    display: inline-block;
    z-index: 9999;
    padding-top: 0.5rem;
}



.time {
    margin-top:0;
    position: relative !important;
    top: -0.5rem !important;
}

.smallTime {
    height: 0!important;
    top: 0 !important;
}

.largeTime {
    height: 0 !important;
    top: -0.5rem !important;
}

.rangeMarginRight {
    margin-right: 1em;
}

.rangeSmallMarginRight {
    margin-right: 0.5em;
}

.row {
    --bs-gutter-x: 1rem !important;
}