.loading-message {
    padding: 25%;
}

.loading-message-row {
    align-items: center;
}

.spinner {
    margin-right: 10px;
    padding: 0px;
}

.loading-message-col {
    text-align: center;
}