.App {
  text-align: center;
  width : 100 vw;
  height : 100 vh;

}
canvas{
width : 100 vw;
height : 100 vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

* {
	font-family: system-ui;
}

.col.py-3 {
    background-color: #28272d !important;
    color: #d7d6d8 !important;
}

.bg-dark {
	background-color: #2c2b30 !important;
	color: #d7d6d8 !important;
}

.nav-link {
	color: #d7d6d8 !important;
}

.nav-link.active {
  background-color: #212529 !important;
}

a {
	color: #d7d6d8 !important;
}

.flex {
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

#root {
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  overflow: auto;

  color: #d7d6d8;
  background-color: #2c2b30;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.stats {
  position: absolute !important;
  left: 100px !important;
}

.noPadding {
  padding: 0!important;
}

