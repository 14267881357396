.scrollbar-height {
    max-height:100vh;
}

.sidebar-container {
    padding: 0.18rem !important;
}

.simplebar-scrollbar::before {
    background-color: #959fa7 !important;
  }

.z1 {
z-index: 1 !important;
}

.noPad {
    padding: 0 !important;
}