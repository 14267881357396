.modal-dialog {
    background-color: #2d2d33 !important;
    color: #d7d6d8 !important;
}

.modal-header {
  background-color: #2d2d33 !important;
  color: #d7d6d8 !important;
}

.modal-body {
  background-color: #2d2d33 !important;
  color: #d7d6d8 !important;
}
